import React from 'react';
import { ContentfulAsset, ContentfulSupplierDocument, Maybe } from '../../types/graphql-types';

import styles from './Supplier.module.scss';

interface Props {
  documents?: Maybe<Array<Maybe<ContentfulSupplierDocument>>>;
  onClick: (pdf: string) => void;
}

function isPdf(document?: ContentfulAsset): boolean {
  return document?.file?.contentType === 'application/pdf';
}

function SupplierDocument(props: Props) {
  return (
    <div className={styles.assets}>
      <ul>
        {props.documents?.map((document, index) => {
          return (
            <li key={index}>
              {document?.document && (
                isPdf(document?.document || undefined) ? (
                  <a
                    className={styles.link}
                    href={document?.document?.file?.url || ''}
                    onClick={(event) => {
                      event.preventDefault();
                      props.onClick(document?.document?.file?.url || '');
                    }}
                  >
                    {document?.title}
                  </a>
                ) : (
                  <a
                    href={document?.document?.file?.url || document?.url || ''}
                    className={styles.link}
                  >
                    {document?.title}
                  </a>
                )
              )}
              {document?.url && (
                <a className={styles.link} href={document?.url || ''}>{document?.title}</a>
              )}
              <p>{document?.description}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SupplierDocument;
