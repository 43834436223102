import React, {useState} from 'react';
import Modal from 'react-modal';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { ContentfulSupplierPage } from '../../types/graphql-types';
import SupplierDocument from './Document';
import SupplierContacts from './Contact';
import SupplierMenu from './Menu';

import styles from './Supplier.module.scss';

interface Props {
  supplier: ContentfulSupplierPage;
}

function Supplier(props: Props) {
  const [modalIsOpen,setIsOpen] = useState(false);
  const [pdf, setPdf] = useState("");

  return (
    <>
      <SupplierMenu/>
      <div className={styles.main}>
        <div className={styles.left}>
          {documentToReactComponents(props.supplier?.content?.json as unknown as Document)}
        </div>
        <div className={styles.right}>
          {props.supplier?.sidebarContainer?.map((sidebar, index) => {
            return (
              <React.Fragment key={index}>
                <h3>{sidebar?.headline}</h3>
                <SupplierDocument
                  documents={sidebar?.documentContainer?.map(document => document) || undefined}
                  onClick={(pdf) => {
                    setPdf(pdf);
                    setIsOpen(true);
                  }}
                />
              </React.Fragment>
            )
          })}
        </div>
      </div>

      {props.supplier?.contactContainer?.length && (
        <div className={styles.supplierContacts}>
          <h1 className={styles.title}>Contact</h1>
          <div className={styles.cards}>
            {props.supplier.contactContainer?.map((contact, index) => {
              return (
                <SupplierContacts
                  key={index}
                  image={contact?.photo?.file?.url || ''}
                  name={contact?.name || ''}
                  job={contact?.rule || ''}
                  phone={contact?.phoneNumber || ''}
                  email={contact?.email || ''}
                />
              );
            })}
          </div>
        </div>
      )}

      <Modal
       onRequestClose={() => {
         setIsOpen(false);
         setPdf("");
       }}
       isOpen={modalIsOpen}
       className={styles.modal}
      >
         <embed src={pdf} />
      </Modal>
    </>
  );
}

export default Supplier;
