import { graphql, PageProps } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Hero/Navbar/Navbar';
import Supplier from '../components/Supplier/Supplier';
import { defaultNavbarLinks } from '../constants/constants';
import { ContentfulSupplierPage } from '../types/graphql-types';
import externalLinks from '../utils/externalLinks';

const SupplierPage: React.FC<PageProps> = ({ data }: PageProps) => {
  const supplier: ContentfulSupplierPage = get(data, 'contentfulSupplierPage');

  return (
    <>
      <Helmet title={`${supplier?.title}`} />

      <Navbar sticky={false} links={defaultNavbarLinks} />

      <Supplier supplier={supplier}/>

      {/* Footer */}
      <Footer
        links={[
          {
            text: 'ONLINESHOP',
            href: externalLinks.onlineshop,
          },
          {
            text: 'KARRIERE',
            href: externalLinks.karriere,
          },
          {
            text: 'Kontakt',
            href: '/contact/',
            target: '_self',
          },
        ]}
        socialMediaLinks={[
          {
            icon: {
              src: require('assets/icons/facebook.svg'),
              alt: 'Facebook page',
            },
            href: externalLinks.facebook,
          },
          {
            icon: {
              src: require('assets/icons/instagram.svg'),
              alt: 'Instagram profile',
            },
            href: externalLinks.instagram,
          },
          {
            icon: {
              src: require('assets/icons/pinterest.svg'),
              alt: 'Pinterest profile',
            },
            href: externalLinks.pinterest,
          },
          {
            icon: {
              src: require('assets/icons/youtube.svg'),
              alt: 'Youtube channel',
            },
            href: externalLinks.youtube,
          },
          {
            icon: {
              src: require('assets/icons/linkedin.svg'),
              alt: 'Linkedin profile',
            },
            href: externalLinks.linkedin,
          },
          {
            icon: {
              src: require('assets/icons/xing.svg'),
              alt: 'Xing profile',
            },
            href: externalLinks.xing,
          },
        ]}
        mobileAppsLinks={[
          {
            icon: {
              src: require('assets/images/apple-store.png'),
              alt: 'ios app',
            },
            href: externalLinks.ios_app,
          },
          {
            icon: {
              src: require('assets/images/google-play-store.png'),
              alt: 'android app',
            },
            href: externalLinks.android_app,
          },
        ]}
        subFooterContent={{
          legalLinks: [
            {
              text: 'Impressum',
              href: externalLinks.impressum,
            },
            {
              text: 'Datenschutz',
              href: externalLinks.datenschutz,
            },
          ],
          copyrightText: `Alle Rechte vorbehalten © HSE ${new Date().getFullYear()}`,
        }}
      />
    </>
  );
};

export const pageQuery = graphql`
  query($contentful_id: String, $locale: String) {
    contentfulSupplierPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      ...Supplier
    }
  }
`;

export default SupplierPage;
