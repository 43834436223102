import React from 'react';

import styles from './Supplier.module.scss';

interface Props {
  image: string;
  name: string;
  job: string;
  phone: string;
  email: string;
}
function SupplierContacts({ image, name, job, phone, email }: Props) {
  return (
    <div className={styles.contacts}>
      <div className={styles.card}>
        <img src={image} alt={name} />
        <h4 className={styles.name}>{name}</h4>
        <h4 className={styles.job}>{job}</h4>
        <div className={styles.info}>
          <p>{phone}</p>
          <p>{email}</p>
        </div>
      </div>
    </div>
  );
}

export default SupplierContacts;
